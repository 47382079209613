<template>
    <div>
      <div class="vx-row margin-btm" style="width: 100%">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Invoice Code</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input class="w-full" v-model="invoice.Code" disabled />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Customer</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input class="w-full" v-model="invoiceData.client.name" disabled />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%">
        <template v-for="sales_order in invoiceData.sales_order">
          <vs-divider
            v-bind:key="sales_order.ID"
            style="width: 100%; margin-left: 2%"
            >Sales Order Code {{ sales_order.Code }}</vs-divider
          >
          <vs-table
            v-bind:key="sales_order.ID"
            stripe
            bordered
            :data="sales_order.Line"
            style="width: 100%; margin-left: 2%"
          >
            <template slot="thead">
              <vs-th>Code</vs-th>
              <vs-th>Name</vs-th>
              <vs-th>Quantity</vs-th>
              <vs-th>Information</vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr :key="index" v-for="(dt, index) in data">
                <vs-td>{{ dt.SkuCode }}</vs-td>
                <vs-td>{{ dt.ItemName }} ({{ dt.Unit }})</vs-td>
                <vs-td>{{ dt.Quantity }}</vs-td>
                <vs-td>
                  Price : {{ priceFormat(dt.Price) }}
                  <br />
                  Discount : {{ priceFormat(dt.Discount) }}
                  <br />
                  Subtotal : {{ priceFormat(dt.Subtotal) }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </template>
      </div>
      <vs-divider style="width: 100%; margin-left: 2%">Total Invoice</vs-divider>
      <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span>SubTotal</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            :value="priceFormat(invoiceData.subtotal)"
            disabled
          />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Discount</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            :value="priceFormat(invoiceData.discount)"
            disabled
          />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Subtotal Charge</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            :value="priceFormat(invoiceData.subtotal_charge)"
            disabled
          />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Other Cost</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            :value="priceFormat(invoiceData.other_cost)"
            disabled
          />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Tax</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            :value="priceFormat(invoiceData.tax_amount)"
            disabled
          />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Total</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            :value="priceFormat(invoiceData.total)"
            disabled
          />
        </div>
      </div>
      <vs-divider style="width: 100%; margin-left: 2%">List Payment</vs-divider>
      <vs-table
        stripe
        bordered
        :data="invoiceData.payment"
        style="width: 100%; margin-left: 2%"
      >
        <template slot="thead">
          <vs-th>No</vs-th>
          <vs-th>Method</vs-th>
          <vs-th>Code</vs-th>
          <vs-th>Reference Code</vs-th>
          <vs-th>Amount</vs-th>
          <vs-th>Note</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :key="index" v-for="(dt, index) in data">
            <vs-td>{{ ++index }}</vs-td>
            <vs-td>{{ dt.PaymentMethod }}</vs-td>
            <vs-td>{{ dt.PaymentCode }}</vs-td>
            <vs-td>{{ dt.ReferenceCode }}</vs-td>
            <vs-td>
              {{ priceFormat(dt.PaymentAmount) }}
              <vs-chip v-if="dt.PaymentCharge" color="danger">
                <span>charge:{{ priceFormat(dt.PaymentCharge) }}</span>
              </vs-chip>
            </vs-td>
            <vs-td>
              {{ dt.Notes }}
            </vs-td>
            <vs-td>
              <vs-chip v-if="dt.Status == 0" color="warning">
                <vs-avatar icon-pack="feather" icon="icon-slash" />
                <span>Waiting</span>
              </vs-chip>
              <vs-chip v-else-if="dt.Status == 3" color="danger">
                <vs-avatar icon-pack="feather" icon="icon-slash" />
                <span>Waiting</span>
              </vs-chip>
              <vs-chip v-else-if="dt.Status == 2" color="success">
                <vs-avatar icon-pack="feather" icon="icon-check" />
                <span>Approve</span>
              </vs-chip>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%"></div>
      <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Paid</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            :value="priceFormat(invoiceData.paid)"
            disabled
          />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Unpaid</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            style="align-text: right"
            :value="priceFormat(invoiceData.unpaid)"
            disabled
          />
        </div>
      </div>
      <div v-if="invoice.Sources ==''" class="mb-5">
        <div class="vx-col w-full">
          <br />
          <vs-button color="green" class="mb-2" v-on:click="handleReversal"
            >Approve </vs-button
          >
        <div class="vx-col w-full">
          <br />
          <vs-button color="red" class="mb-2" v-on:click="handleReversalCancel"
            >Reject</vs-button
          >
    </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span></span>
        </div>
        <div
          class="vx-col sm:w-2/3 w-full"
          v-if="
            this.$route.path == '/billing' ||
            this.$route.path == '/consignment-sales'
          "
        >
          <vs-button
            v-if="invoiceData.unpaid <= 0"
            color="blue"
            type="line"
            icon-pack="feather"
            @click="closeInvoice()"
            icon="icon-save"
            >Close</vs-button
          >
          <!-- <vs-button
            v-if="invoiceData.unpaid > 0"
            color="red"
            type="line"
            icon-pack="feather"
            @click="forceClose()"
            icon="icon-save"
            >Adjust Force Close</vs-button
          > -->
        </div>
      </div>
      </div>
    </div>
  </template>
  <script>
  import vSelect from "vue-select";
  import Datepicker from "vuejs-datepicker";
  import moment from "moment";
  export default {
    components: {
      Datepicker,
      "v-select": vSelect,
    },
    props: {
      invoice: Object,
    },
    watch:{
      "invoice.ID"() {
        this.getDataInvoice();
      }
    },
    mounted() {
      console.log(this.invoice);
      this.getDataInvoice();
      this.reversalDate = new Date();
    },
    data() {
      return {
        invoiceData: {
          id: 0,
          code: "",
          faktur_number: "",
          company: {
            code: "",
            name: "",
          },
          delivery_order: {},
          client: {
            id: "",
            code: "",
            name: "",
          },
          bill_to: {},
          notes: "",
          date: "",
          due_date: "",
          time: "",
          subtotal: 0,
          tax_rate: 0,
          tax_amount: 0,
          discount: 0,
          other_cost: 0,
          total: 0,
          sources:"",
          status: 0,
          sales_order: [],
          payment: [],
          delete_sales_order: [],
        },
        payment_method: 1,
        bank: 1,
        bankName: "",
        bankNumber: "",
        bank_account: {},
        invoice_payment_cod: [],
        payment_amount: 0,
        payment_code: "",
        reversalDate:{},
      };
    },
    methods: {
      getDataInvoice() {
        this.$vs.loading();
        this.$http
          .get("api/v1/invoice/read", {
            params: {
              invoice_id: this.invoice.ID,
            },
          })
          .then((resp) => {
            if (resp.code == 200) {
              // const asyncFunction = async () => {
              this.invoiceValue(resp.data);
            } else {
              // //console.log(resp.code)
              this.$vs.dialog({
                type: "alert",
                color: "danger",
                title: `Alert`,
                text: "Whoops",
              });
            }
            this.$nextTick(() => {
              this.$vs.loading.close();
            });
          });
      },
      closeDetail() {
        this.$emit("closeDetail", true);
      },
      forceClose() {
        this.$http
          .put("api/v1/invoice/status/update", {
            invoice_ids: [this.invoice.ID],
            status: parseInt(5),
          })
          .then((resp) => {
            if (resp.code == 200) {
              this.$vs.notify({
                title: "Success",
                text: "",
                color: "success",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
              // this.reloadData(this.params);
            } else {
              this.$vs.notify({
                title: "Danger",
                text: resp.message,
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
            }
            this.$nextTick(() => {
              // this.$emit("closeDetail");
              this.$emit("closeDetail", true);
              this.$vs.loading.close();
            });
          });
        // this.closeDetail();
      },
      dateFormat(value) {
        return moment(String(value)).format("DD/MM/YYYY");
      },
      formatPrice(angka, prefix = "") {
        console.log(angka);
        var number_string = angka.replace(/[^,\d]/g, "").toString();
        var split = number_string.split(",");
        var sisa = split[0].length % 3;
        var rupiah = split[0].substr(0, sisa);
        var ribuan = split[0].substr(sisa).match(/\d{3}/gi);
        var separator = "";
        if (ribuan) {
          if (sisa) {
            separator = ".";
          } else {
            separator = "";
          }
          rupiah += separator + ribuan.join(".");
        }
        rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
        return prefix == undefined ? rupiah : rupiah ? "" + rupiah : "";
      },
      postInvoiceCustomer() {
        this.$vs.loading();
        var file = this.$refs.file.files[0];
        if (file != null) {
          var validImageTypes = ["image/gif", "image/jpeg", "image/png"];
          if (!validImageTypes.includes(file.type)) {
            this.$vs.notify({
              color: "danger",
              title: "Form Validation",
              text: "File must be an image",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            
            // console.log(this.invoiceData.code.replace(/\//g, '-') + this.payment_amount.replace(/[^,\d]/g, "").toString())
            // return this.invoiceData.code.replace('/', 'ForwardSlash')
            const formData = new FormData();
            formData.append("file", file);
            formData.append("invoice_id", this.invoiceData.ID);
            formData.append("invoice_code", this.invoiceData.code);
            formData.append("payment_code", this.payment_code);
            formData.append("bank", this.bank.Name);
            formData.append("bank_name", this.bankName);
            formData.append("imgType", file.name.split(".").pop());
            formData.append("territory_id", this.$userLogin.territory_id);
            formData.append("territory_area_id", this.$userLogin.territory_area_id);
            formData.append("amount", parseInt(this.payment_amount.replace(/[^,\d]/g, "").toString()));
            formData.append("name", this.invoiceData.code.replace(/\//g, '-') + this.payment_amount.replace(/[^,\d]/g, "").toString() + '.' + file.name.split(".").pop());
            this.$http
                  .post("api/v1/invoice/store/payment/customer", formData, {
                    headers: {
                      "Content-Type": "multipart/form-data"
                    }
                  })
                  .then(resp => {
                    console.log(resp)
                    this.$vs.loading.close();
                    if (resp.status == "success") {
                      this.$vs.notify({
                        color: "success",
                        title: "Success",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                      });
                      this.$nextTick(() => {
                        this.getDataInvoice()
                      });
                    } else {
                      this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                      });
                    }
  
                  });
          }
        } else {
          this.$vs.notify({
            color: "danger",
            title: "Form Validation",
            text: "File must",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
        }
      },
      invoiceValue(invoice) {
        this.invoiceData.items = [];
        this.invoiceData.delete_items = [];
        this.invoiceData.ID = invoice.invoice.ID;
        this.invoiceData.code = invoice.invoice.Code;
        this.invoiceData.delivery_order = invoice.deliveryOrder;
        (this.invoiceData.faktur_number = invoice.invoice.FakturNumber),
          (this.invoiceData.client = {
            id: invoice.customer.ID,
            address: invoice.customer.address,
            phone: invoice.customer.phone,
            code: invoice.invoice.ClientCode,
            name: invoice.invoice.ClientName,
          });
        this.invoiceData.client_code = invoice.invoice.ClientCode;
        this.invoiceData.client_name = invoice.invoice.ClientName;
        this.invoiceData.company_code = invoice.invoice.CompanyCode;
        this.invoiceData.company_name = invoice.invoice.CompanyName;
        this.invoiceData.notes = invoice.invoice.Note;
        this.invoiceData.date = this.dateFormat(
          invoice.invoice.Date.toString()
        );
        this.invoiceData.due_date = this.dateFormat(
          invoice.invoice.DueDate.toString()
        );
        //   console.log(invoice.salesOrder[0].DueDate);
        this.invoiceData.time = invoice.invoice.Time;
        this.invoiceData.subtotal = invoice.invoice.Subtotal;
        this.invoiceData.tax_rate = invoice.invoice.TaxRate;
        this.invoiceData.tax_amount = invoice.invoice.TaxAmount;
        this.invoiceData.discount = invoice.invoice.Discount;
        this.invoiceData.other_cost = invoice.invoice.OtherCost;
        this.invoiceData.subtotal_charge = invoice.invoice.SubtotalCharge;
        this.invoiceData.total = invoice.invoice.Total;
        this.invoiceData.tax_amount = invoice.invoice.TaxAmount;
        this.invoiceData.tax_rate = invoice.invoice.TaxRate;
        this.invoiceData.status = invoice.invoice.Status;
        this.invoiceData.payment = invoice.payment;
        this.invoiceData.bank = invoice.bank;
        this.bank = invoice.bank[0];
        this.invoiceData.bank_account = invoice.bankAccount;
        this.payment_method = invoice.paymentMethod[2];
        this.invoice_payment_cod = invoice.invoicePaymentCOD;
  
        this.invoiceData.payment_method = invoice.paymentMethod;
        this.invoiceData.sales_order = [];
        for (var x in invoice.invoiceLine) {
          invoice.invoiceLine[x].total = invoice.invoiceLine[x].Line.reduce(
            (acc, value) => acc + value.Total,
            0
          );
          this.invoiceData.sales_order.push(invoice.invoiceLine[x]);
          for (var y in this.invoiceData.sales_order[x].Line) {
            this.invoiceData.sales_order[x].Line[
              y
            ].Quantity = this.invoiceData.sales_order[x].Line[y].Qty;
            this.invoiceData.sales_order[x].Line[
              y
            ].SellPrice = this.invoiceData.sales_order[x].Line[y].Price;
          }
        }
        this.invoiceData.paid = invoice.payment.reduce(
          (acc, value) => acc + value.PaymentAmount,
          0
        );
        this.invoiceData.unpaid =
          parseFloat(this.invoiceData.total) - parseFloat(this.invoiceData.paid);
        this.payment_amount = this.formatPrice(
          this.invoiceData.unpaid.toString()
        );
        const now = new Date();
        this.now = this.dateFormat(now.toString());
        return true;
      },
      handleClose() {
      window.scrollTo(0, 0);
      this.$emit("closeDetail",true);
      },
      handleReversal() {
        console.log(this.invoice,this.reversalDate)
        
        const params = {
          id:this.invoice.ID,
          status_inv:7,
        }
        this.$vs.loading();
        //console.log(params)
        this.$http.post('/api/v1/invoice/ConfirmReversal',params)
          .then(result => {
            //console.log(result)
            this.$vs.loading.close();
            if (result.code == 200) {
              this.handleClose();
              this.$vs.notify({
                title: "Success",
                text: "",
                color: "success",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
              
            } else {
              this.$vs.notify({
                title: "Error",
                text: result.message,
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
            }
            this.closeDetail()
          }).catch(e => {
  
          })
      
      },
      handleReversalCancel() {        
        const params = {
          id:this.invoice.ID,
          status_inv: 2,
        }
        this.$vs.loading();
        //console.log(params)
        this.$http.post('/api/v1/invoice/ConfirmReversal',params)
          .then(result => {
            //console.log(result)
            this.$vs.loading.close();
            if (result.code == 200) {
              this.handleClose();
              this.$vs.notify({
                title: "Success",
                text: "",
                color: "success",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
              
            } else {
              this.$vs.notify({
                title: "Error",
                text: result.message,
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
            }
          }).catch(e => {
  
          })
      
      },
    },
  };
  </script>
  <style scoped>
  .margin-btm {
    margin-bottom: 1rem !important;
  }
  .vs-input--input.normal {
    text-align: end;
  }
  </style>